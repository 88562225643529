
.container {
  width: 100%;
}
.margin6 {
  margin-right: 3px;
}

.sv_header {
  background-color: white;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q {
  background-color: white;
}
.svd_container .svd_commercial_container {
  display: none;
}
.sa-commercial {
  display: none;
}

.btn {
  cursor: pointer;
}
#editor-container {
  height: 200px;
}

.ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button {
  min-width: 15px;
}

.ql-editor {
  min-height: 200px;
}
